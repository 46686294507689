
              @import "@/assets/css/variables.scss";
            






























































@import '@/assets/css/components/promotion/blueHeadTable.scss';
.table-title.with-account {
  margin-bottom: 16px;
}
.account-context {
  margin-top: 4px;
  margin-bottom: 16px;
}
