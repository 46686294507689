.l-select {
  position: relative;
  position: sticky;
  z-index: 101;

  input {
    position: sticky;
    z-index: 101;
  }

  &.active,
  &[value]:not([value='']) {
    /deep/ label {
      font-size: 14px;
      bottom: 45px;
    }
  }
}

/deep/ .el-form-item__label {
  text-transform: inherit;
}

/deep/ .el-form-item {
  margin-bottom: 20px;
}

/deep/ .el-form-item.is-required {
  .el-form-item__label {
    &::before {
      display: none;
    }

    &::after {
      content: '*';
      color: $light-red !important;
      margin-right: 4px;
    }
  }
}

.dialog-content {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  margin-bottom: 26px;
}

// custom dialog style
.common-dialog-wrapper /deep/ {
  .dialog.el-dialog__wrapper .el-dialog.el-dialog--center {
    height: auto;
    max-height: 360px;
    width: 100%;
  }
  .dialog-body {
    padding: 0 0 45px 0;
  }
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}
.submit-btn {
  width: 192px;
}

@media (min-width: 550px) {
  .common-dialog-wrapper /deep/ {
    .dialog-body {
      padding: 0 35px 45px 30px;
    }
  }
}

@media (min-width: 768px) {
  .common-dialog-wrapper /deep/ {
    .el-dialog__body {
      padding: 30px 68px !important;
    }
  }
  .submit-btn {
    height: 60px;
  }
}
