
              @import "@/assets/css/variables.scss";
            













































































@import '@/assets/css/components/promotion/blueHeadTable.scss';
/deep/ .el-table {
  th {
    font-size: 12px;
  }
}

@media (min-width: 640px) {
  /deep/ .el-table {
    th {
      font-size: 15px;
    }
  }
}
